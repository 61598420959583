import React, {  useState } from "react";
import ProductListShow from "./ProductListShow";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { getProList10, getProductsList } from "../action/MainAction";
import {  loaderTwoFunc } from "../redux/MainReducer";
import Loading from "./Loading";

const Products = ({
  producstListSelectCategory,
  pageNumbers, 
  allProductsStatus,
}) => {
 
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const displayCount = 5; // Количество отображаемых чисел
  const activeIndex = useSelector((state) => state.Data.activeIndex);

 
  const handleClick = (pageNumber) => {
    dispatch(loaderTwoFunc())
    window.scroll({top:350, behavior:"smooth"})
    setCurrentPage(pageNumber);
    if (allProductsStatus) {
      dispatch(getProList10(pageNumber));
      // dispatch(loaderTwoFunc(false))
      
    }else{
      dispatch(getProductsList(activeIndex, pageNumber));  
      // dispatch(loaderTwoFunc(false))
      
    }
  };
  const loaderTwo = useSelector(state=>state.Data.loaderTwo)
  

  const renderPageNumbers = () => {

    const totalPages = pageNumbers.length;

    if (totalPages <= displayCount) {
      return pageNumbers.map((num, i) => (
        <li
          key={i}
          className={`page-li ${num === currentPage ? "active_number" : ""}`}
          onClick={() => handleClick(num)}
        >
          <a href="#!" className="page-link">
            {num}
          </a>
        </li>
      ));
    }

    const middleIndex = Math.ceil(displayCount / 2);
    const start =
      currentPage <= middleIndex
        ? 1
        : currentPage >= totalPages - middleIndex
        ? totalPages - displayCount + 1
        : currentPage - middleIndex + 1;

    const visiblePageNumbers = pageNumbers.slice(
      start - 1,
      start - 1 + displayCount
    );

    return (
      <>
        {start > 1 && (
          <>
            <li className="page-li" onClick={() => handleClick(1)}>
              <a href="#!" className="page-link">
                1
              </a>
            </li>
            <li>
              <span>...</span>
            </li>
          </>
        )}
        {visiblePageNumbers.map((num, i) => (
          <li
            key={i}
            className={`page-li ${num === currentPage ? "active_number" : ""}`}
            onClick={() => handleClick(num)}
          >
            <a href="#!" className="page-link">
              {num}
            </a>
          </li>
        ))}
        {start + displayCount - 1 < totalPages && (
          <>
            <li>
              <span>...</span>
            </li>
            <li className="page-li" onClick={() => handleClick(totalPages)}>
              <a href="#!" className="page-link">
                {totalPages}
              </a>
            </li>
          </>
        )}
      </>
    );
  };
  
  return (
    <div className="products_container">
      <div className="products_container">
        <div className="products_box">

          {loaderTwo ? <Loading/>: producstListSelectCategory?.map((data) => (
            <ProductListShow key={data.id} data={data} />
          ))}
        </div>

        <div>
          <ul className="pagination">
            <FaChevronLeft />
            {renderPageNumbers()}
            <FaChevronRight />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Products;
