import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import SelectedCategory from "../components/SelectedCategory";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import titles from "./titles/titles";

const CategoryPage = () => {
  return (
    <div>
      <Helmet>
        <title>{titles.category}</title>
      </Helmet>
      <Navbar />
      <Hero />
      <SelectedCategory />
      <Footer />
    </div>
  );
};

export default CategoryPage;
