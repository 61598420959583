import React from "react";
import Navbar from "../components/Navbar";
import SinglePage from "../components/SinglePage";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import { Helmet } from "react-helmet";
import titles from "./titles/titles";

const SingleProdPage = () => {
  return (
    <div>
      <Helmet>
        <title>{titles.product}</title>
      </Helmet>
      <Navbar />
      <Hero />
      <SinglePage />
      <Footer />
    </div>
  );
};

export default SingleProdPage;
