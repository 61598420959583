import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../images/leadtech_logo.png";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { getContactInfo } from "../action/MainAction";
import { useDispatch, useSelector } from "react-redux";
// import santahat from "../images/santahat.png";

const Navbar = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language, () => {
      // Колбэк вызывается после успешного изменения языка
      window.location.reload();
    });
  };

  const [isOpen, setIsOpen] = useState(false);

  const setOpenedState = () => {
    setIsOpen(!isOpen);
  };

  // UseEffect for contact info

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContactInfo());
  }, []);

  const contactInfo = useSelector((state) => state.Data.contactInfo);

  return (
    <header>
      <div className="header_container">
        <div className="header_logo">
          <Link to="/">
            <img src={logo} alt="logo_leadtech" />
          </Link>
        </div>
        <div className="burger_menu" onClick={setOpenedState}>
          <span
            className={!isOpen ? "burger_class" : "burger_class clicked"}
          ></span>
          <span
            className={!isOpen ? "burger_class" : "burger_class clicked"}
          ></span>
          <span
            className={!isOpen ? "burger_class" : "burger_class clicked"}
          ></span>
        </div>
        <div className={!isOpen ? "phone_number" : "phone_number open"}>
          <div className="headset">
            <div className="headphone">
              <svg
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="vaadin:headset" clipPath="url(#clip0_122_4218)">
                  <path
                    id="Vector"
                    d="M28.1908 15.5C27.9115 14.7211 27.4647 14.013 26.8819 13.4256C26.299 12.8383 25.5944 12.386 24.8177 12.1006L24.7783 8.9375C24.7783 4.25 20.1471 0.5 14.4658 0.5C8.78457 0.5 4.15332 4.25 4.15332 8.9375V12.0875C3.06007 12.4757 2.11327 13.1919 1.44213 14.1382C0.770986 15.0845 0.408213 16.2149 0.40332 17.375L0.40332 19.25C0.40332 20.7418 0.995952 22.1726 2.05084 23.2275C3.10574 24.2824 4.53648 24.875 6.02832 24.875H7.90332V11.75H6.02832V8.9375C6.02832 5.31875 9.77832 2.375 14.4658 2.375C19.1533 2.375 22.9033 5.31875 22.9033 8.9375V11.75H21.0283V24.875H22.9033C24.3952 24.875 25.8259 24.2824 26.8808 23.2275C27.9357 22.1726 28.5283 20.7418 28.5283 19.25V22.4937C28.5283 24.1199 27.8824 25.6794 26.7325 26.8292C25.5827 27.979 24.0232 28.625 22.3971 28.625H19.1533C19.1533 28.1277 18.9558 27.6508 18.6041 27.2992C18.2525 26.9475 17.7756 26.75 17.2783 26.75H15.4033C14.906 26.75 14.4291 26.9475 14.0775 27.2992C13.7259 27.6508 13.5283 28.1277 13.5283 28.625C13.5283 29.1223 13.7259 29.5992 14.0775 29.9508C14.4291 30.3025 14.906 30.5 15.4033 30.5H22.3971C24.5205 30.5 26.5569 29.6565 28.0583 28.155C29.5598 26.6536 30.4033 24.6171 30.4033 22.4937V15.5H28.1908Z"
                    fill="#FED700"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_122_4218">
                    <rect
                      width="30"
                      height="30"
                      fill="white"
                      transform="translate(0.40332 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              {/* Santa hat for new year */}
              {/* <div className="santa_hat">
                <img src={santahat} alt="" />
              </div> */}
            </div>
            <p className="number">
              Tel:{" "}
              <div className="number_hover">

              <a href={`tel:${contactInfo[0]?.phone}`} className="num">{contactInfo[0]?.phone}</a>
                
              </div>
            </p>
          </div>
          <Link to="/contact" className="go_to_contact ">
            {t("contact")}
          </Link>
          <div className="lang_menu">
            <button onClick={() => changeLanguage("az")}>AZ</button>
            <span>|</span>
            <button onClick={() => changeLanguage("ru")}>RU</button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
