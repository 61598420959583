import React, { useState } from "react";
import SubSubMenuItems from "./SubSubMenuItems";
import { IoIosArrowForward } from "react-icons/io";
import i18n from "../18n";
// import { useNavigate } from "react-router-dom";

const SubMenuItems = ({ data, imageCat, categorytList, setIsOpen, isOpen }) => {
  // const navigate = useNavigate();

  const [selectCategory, setSelectCategory] = useState(null);

  const selectCategoryFunc2 = (data) => {
    let filterData2 = categorytList.filter((x) => x?.parent?.id === data.id);
    setSelectCategory(filterData2);
  };

  const currentLanguage = i18n.language;

  const openSubCategory = (x) => {
    const formattedCategory = x.translations.az.name.toLowerCase();

    const replacedCategory = formattedCategory.replace(
      /[əğöüş]/g,
      (char) => ({ ə: "e", ğ: "g", ö: "o", ü: "u", ş: "sh" }[char])
    );
    const sanitizedCategory = replacedCategory
      .replace(/[^\w-]/g, "-")
      .replace(/--+/g, "-");

    const url = categorytList.some((data) => data?.parent?.id === x.id)
      ? `/category/subcategory/${sanitizedCategory}?id=${x.id}`
      : `/category/${sanitizedCategory}?id=${x.id}`;

    // navigate(url);
    window.location.href = url;

    setIsOpen(!isOpen);
  };

  return (
    <div className="grid-container">
      {/* <h1 className="item-title">{item.name}</h1> */}

      <div style={{overflow:'auto'}} className="sub_prod_list_container" >
        {data.map((item, i) => (
          <ul key={i} className="products-list">
            <li
              className="sub_item_link"
              style={{ border: "none" }}
              onMouseOver={() => selectCategoryFunc2(item)}
            >
              <div className="sub_prod_catg">
                {/* <img className="component" src={category.img} alt="comp" /> */} 
                <p
                  onClick={() => openSubCategory(item)}
                  className="text_wrapper"
                >
                  {item?.translations[currentLanguage]?.name}
                </p>
              </div>
              {categorytList.find((x) => x?.parent?.id === item.id) ? (
                <IoIosArrowForward className="icon-outline" />
              ) : (
                ""
              )}
            </li>
          </ul>
        ))}
      </div>
      <div className="second_scroll_of_page">
        {selectCategory && (
          <SubSubMenuItems
            selectCategory={selectCategory}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            categorytList={categorytList}
          />
        )}
      </div>
      <div className="image-item">
        <img src={imageCat} alt="" /> 
      </div>
    </div>
  );
};

export default SubMenuItems;
