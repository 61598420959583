import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getContactInfo } from "../action/MainAction";

const Footer = () => {
  const { t } = useTranslation();

  // UseEffect for contact info

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContactInfo());
  }, []);

  const contactInfo = useSelector((state) => state.Data.contactInfo);

  return (
    <footer>
      <div className="footer_container">
        <div className="contact">
          <h6>{t("contactFooter")}</h6>

          <a href={`tel:${contactInfo[0]?.phone}`}>{contactInfo[0]?.phone}</a>
          <p className="contact_email">
          <a href={`mailto:${contactInfo[0]?.email}`}>{contactInfo[0]?.email}</a>
          
          </p>
        </div>
        <div className="social_net">
          <h6>{t("stayWithUs")}</h6>
          <ul className="stay_with_us">
            <li>
              <Link to="https://wa.me/+994102603902" target="_blank">
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Link &#226;&#134;&#146; SVG"
                    clipPath="url(#clip0_122_4461)"
                  >
                    <path
                      id="Vector"
                      d="M23.1084 5.54986C19.5183 -0.000181198 12.1885 -1.6502 6.50421 1.79983C0.96946 5.24986 -0.825598 12.7499 2.76451 18.3L3.06369 18.75L1.86699 23.25L6.35462 22.05L6.80338 22.35C8.74802 23.4 10.8423 24 12.9365 24C15.1803 24 17.4241 23.4 19.3687 22.2C24.9035 18.6 26.5489 11.2499 23.1084 5.54986ZM19.9671 17.0999C19.3687 18 18.6208 18.6 17.5737 18.75C16.9753 18.75 16.2274 19.05 13.2356 17.85C10.6927 16.6499 8.59843 14.6999 7.10256 12.4499C6.20503 11.3999 5.75627 10.0499 5.60668 8.69988C5.60668 7.49987 6.05544 6.44987 6.80338 5.69986C7.10256 5.39986 7.40173 5.24986 7.70091 5.24986H8.44885C8.74802 5.24986 9.0472 5.24986 9.19679 5.84986C9.49596 6.59987 10.2439 8.39988 10.2439 8.54988C10.3935 8.69988 10.3935 8.99989 10.2439 9.14989C10.3935 9.44989 10.2439 9.74989 10.0943 9.89989C9.94473 10.0499 9.79514 10.3499 9.64555 10.4999C9.34637 10.6499 9.19679 10.9499 9.34637 11.2499C9.94472 12.1499 10.6927 13.0499 11.4406 13.7999C12.3381 14.5499 13.2356 15.1499 14.2827 15.5999C14.5819 15.7499 14.8811 15.7499 15.0307 15.4499C15.1803 15.1499 15.9282 14.3999 16.2274 14.0999C16.5266 13.7999 16.6762 13.7999 16.9753 13.9499L19.3687 15.1499C19.6679 15.2999 19.9671 15.4499 20.1167 15.5999C20.2663 16.0499 20.2663 16.6499 19.9671 17.0999Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_122_4461">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0.90332)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </Link>
            </li>

            <li>
              <Link to="https://www.instagram.com/leadtech.az/" target="_blank">
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Link &#226;&#134;&#146; SVG"
                    clipPath="url(#clip0_122_4455)"
                  >
                    <path
                      id="Vector"
                      d="M12.9001 7.9983C10.6967 7.9983 8.89847 9.79651 8.89847 12C8.89847 14.2035 10.6967 16.0017 12.9001 16.0017C15.1035 16.0017 16.9017 14.2035 16.9017 12C16.9017 9.79651 15.1035 7.9983 12.9001 7.9983ZM24.902 12C24.902 10.3429 24.917 8.70077 24.8239 7.04665C24.7308 5.12536 24.2926 3.4202 22.8876 2.01525C21.4797 0.607302 19.7776 0.172008 17.8563 0.0789456C16.1992 -0.0141173 14.5572 0.000892943 12.9031 0.000892943C11.246 0.000892943 9.60393 -0.0141173 7.94984 0.0789456C6.02858 0.172008 4.32346 0.610305 2.91854 2.01525C1.51061 3.42321 1.07533 5.12536 0.982265 7.04665C0.889203 8.70377 0.904213 10.3459 0.904213 12C0.904213 13.6541 0.889203 15.2992 0.982265 16.9533C1.07533 18.8746 1.51361 20.5798 2.91854 21.9847C4.32646 23.3927 6.02858 23.828 7.94984 23.9211C9.60693 24.0141 11.249 23.9991 12.9031 23.9991C14.5602 23.9991 16.2022 24.0141 17.8563 23.9211C19.7776 23.828 21.4827 23.3897 22.8876 21.9847C24.2956 20.5768 24.7308 18.8746 24.8239 16.9533C24.92 15.2992 24.902 13.6571 24.902 12ZM12.9001 18.1572C9.49286 18.1572 6.74305 15.4073 6.74305 12C6.74305 8.5927 9.49286 5.84284 12.9001 5.84284C16.3073 5.84284 19.0571 8.5927 19.0571 12C19.0571 15.4073 16.3073 18.1572 12.9001 18.1572ZM19.3093 7.02864C18.5138 7.02864 17.8713 6.38621 17.8713 5.59067C17.8713 4.79513 18.5138 4.1527 19.3093 4.1527C20.1048 4.1527 20.7472 4.79513 20.7472 5.59067C20.7475 5.77957 20.7104 5.96667 20.6383 6.14124C20.5661 6.31581 20.4602 6.47442 20.3266 6.608C20.193 6.74157 20.0344 6.84748 19.8598 6.91967C19.6853 6.99185 19.4982 7.02888 19.3093 7.02864Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_122_4455">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0.90332)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
