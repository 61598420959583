// 18n.js

import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const LANGUAGE_STORAGE_KEY = "selectedLanguage";

const selectedLanguage = localStorage.getItem(LANGUAGE_STORAGE_KEY); 

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "az",
    debug: false,
    detection: {
      order: ["queryString", "cookie"],
      cache: ["cookie"],
    },
    
    interpolation: {
      escapeValue: false,
    },
    lng: selectedLanguage || "az", // Используем сохраненный язык или 'az' по умолчанию
  });

i18n.on("languageChanged", (lng) => {
  localStorage.setItem(LANGUAGE_STORAGE_KEY, lng);
});

export default i18n;
