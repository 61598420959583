export const initialState = {
  categorytList: [],
  productList: [],
  categoryClickList: null,
  categoryAtributes:[],
  producstList:[],
  productIDList:[],
  allProductsStatus: true,
  productsStatus: false, 
  activeIndex: null, 
  productSid: null,
  carouselImages:[],
  cardsImage:[],
  loader: true,
  loaderBtns: false,
  loaderAllBtns: false, 
  loaderTwo: true,
  forVideoRek:[],
  getProList10Value:[], 
  filterResults: [],  
  searchResult:[],
  contactInfo:[],
  partnersImg:[],
  instCards:[]
  
  
 
}; 
