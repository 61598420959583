import React, { useEffect, useState } from "react";
import Products from "./Products";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryList,
  getProList10,
  getProductsList, 
} from "../action/MainAction";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";

import {
  allProductsBtn,
  handleClick,
  setProductSid,
} from "../redux/MainReducer";
import { useTranslation } from "react-i18next";
import Loading from "./Loading";

const Title = () => {
  const dispatch = useDispatch();

  const categorytList = useSelector((state) => state.Data.categorytList);
  const productSid = useSelector((state) => state.Data.productSid);
  const activeIndex = useSelector((state) => state.Data.activeIndex);

  const producstList = useSelector((state) => state.Data.producstList);
 
  // const productList = useSelector((state) => state.Data.productList);
  const allProductsStatus = useSelector(
    (state) => state.Data.allProductsStatus
  );
  const loaderBtns = useSelector((state) => state.Data.loaderBtns);
  // console.log(loaderBtns);

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const numberOfDisplayedCategories = 10;
  const [displayedRange, setDisplayedRange] = useState({
    start: 0,
    end: numberOfDisplayedCategories,
  });

  // Переместим определение функции hasChildren перед использованием
  const hasChildren = (categoryId, categories) => {
    return categories.some(
      (category) => category.parent && category.parent.id === categoryId
    );
  };

  const topLevelCategories = categorytList.filter(
    (category) => !hasChildren(category.id, categorytList)
  );

  const showNextCategories = () => {
    setDisplayedRange((prevRange) => ({
      start: prevRange.end,
      end: prevRange.end + numberOfDisplayedCategories,
    }));
  };

  const showPreviousCategories = () => {
    setDisplayedRange((prevRange) => ({
      start: Math.max(0, prevRange.start - numberOfDisplayedCategories),
      end: prevRange.start,
    }));
  };

  useEffect(() => {
    dispatch(getCategoryList());
    dispatch(getProductsList(productSid)); 
    dispatch(setProductSid());
    dispatch(getProList10());
  }, [dispatch, productSid]);

  const getProList10Value = useSelector(
    (state) => state.Data.getProList10Value
  );
  const totalPages = Math.ceil(getProList10Value.count / 10);
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  const totalPages2 = Math.ceil(producstList.count / 10);
  const pageNumbers2 = Array.from(
    { length: totalPages2 },
    (_, index) => index + 1
  );

  return (
    <div className="title_container">
      <ul className="title_name">
        <li
          onClick={() => dispatch(allProductsBtn())}
          className={allProductsStatus ? "active_title_name" : ""}
        >
          {t("allView")}
        </li>

        {/* Ваша кнопка здесь и отображаение названий дочерных категорий */}

        {displayedRange.start > 0 && (
          <li onClick={showPreviousCategories} className="ellipsis">
            <FaAnglesLeft className="arrow_cat_list" />
            ...
          </li>
        )}

        {topLevelCategories
          .slice(displayedRange.start, displayedRange.end)
          .map((data, i) => (
            <li
              key={i}
              className={
                data.id === activeIndex && !allProductsStatus
                  ? "active_title_name"
                  : ""
              }
              onClick={() => {
                dispatch(handleClick(data.id));
              }}
            >
              {data?.translations[currentLanguage]?.name ||
                data?.translations?.az.name}
            </li>
          ))}

        {displayedRange.end < topLevelCategories.length && (
          <li onClick={showNextCategories} className="ellipsis">
            ...
            <FaAnglesRight className="arrow_cat_list" />
          </li>
        )}
      </ul>

      {/* Отображаение товаров */}
      {loaderBtns ? (
        <Loading />
      ) : (
        <Products 
        allProductsStatus={allProductsStatus}
        pageNumbers={allProductsStatus===true ? pageNumbers:pageNumbers2}
          // pageNumbers={allProductsStatus === true ? pageNumbers : producstList}
          producstListSelectCategory={
            allProductsStatus === true
              ? getProList10Value.results
              : producstList.results
          }
        />
      )}
    </div>
  );
};

export default Title;
