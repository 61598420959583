import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Category from "../components/Category";
import Title from "../components/Title";
import Partners from "../components/Partners";
import Footer from "../components/Footer";
import HomeSlide from "../components/HomeSlide";
import { Helmet } from "react-helmet";
import titles from "./titles/titles";
import HomeModal from "../components/HomeModal";

const Home = () => {
  return (
    <div>
      {/* <HomeModal/> // Этот компонент отвечает за сплывающее окно */}
      <Helmet>
        <title>{titles.home}</title> 
        <meta name="keywords" content="leadtech.az - mobil telefonlar, planşetlər, məişət texnikası və elektronika İnternet mağaza Bakı. Digər avadanlıqlar. Azərbaycan üzrə texnika."></meta>
        <meta name="description" content="leadtech.az - mobil telefonlar, planşetlər, məişət texnikası və elektronika İnternet mağaza Bakı. Digər avadanlıqlar. Azərbaycan üzrə texnika. Maksimum seçim"></meta>
      </Helmet>
      <Navbar />
      <Hero />
      <HomeSlide />
      <Category />
      <Title />
      <Partners />
      <Footer />
    </div>
  );
};

export default Home;
